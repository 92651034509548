import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Badge } from 'react-bootstrap'
import dynamic from 'next/dynamic'

import styles from './style.module.scss'
import noImage from '@assets/images/placeholder/article-placeholder.jpg'
import time from '@assets/images/icon/clock-icon.svg'
import {
  convertDt24h,
  convertHMS,
  dateCheck,
  getArticleImg,
  getImgURL
} from '@shared-submodule/utils'
import { BLUR_DATA_URL_BASE64, DEFAULT_BLOG_READ } from '@shared-submodule/sm-constants'
import CustomLink from '@shared/components/customLink'
import MyImage from '@shared/components/myImage'
import { PlayIcon } from '@shared/components/ctIcons'
import Calendar from '@assets/images/placeholder/calendar.svg'
import View from '@assets/images/placeholder/view.svg'
const ArticleStyles = dynamic(() => import('@shared/components/article/articleStyle'))

function ArticleSmall({ data, isLarge, isVideo, hideBadge, isSmall, index, isFirstImg }) {
  return (
    <ArticleStyles>
      {(articleStyles) => (
        <article id={data?._id} className={`${articleStyles.article} ${styles.articleSmall} ${isVideo && articleStyles.video} ${isSmall && styles.smallSize}`}>
          <Row className="row-4">
            <Col sm={4} xs={5}>
              <CustomLink href={`/${data?.oSeo?.sSlug}` || ''} prefetch={false}>
                <a className={`${articleStyles.postimg} ${styles.postimg} d-block block-img`}>
                  {
                    isVideo && (
                      <>
                        <MyImage
                          priority={isFirstImg}
                          src={getImgURL(data?.sThumbnailUrl) || noImage}
                          alt={data?.sTitle}
                          blurDataURL={BLUR_DATA_URL_BASE64}
                          placeholder="blur"
                          height={10}
                          width={16}
                          layout="responsive"
                          sizes="(max-width: 767px) 120px, (max-width: 991px) 180px, (max-width: 1190px) 200px, 240px"
                        />
                        <div className={styles.play}>
                          <PlayIcon />
                        </div>
                      </>
                    )
                  }
                  {
                    !isVideo && (
                      <MyImage
                        priority={isFirstImg}
                        src={getArticleImg(data)?.sUrl || noImage}
                        alt={getArticleImg(data)?.sText || data?.sSrtTitle}
                        placeholder="blur"
                        blurDataURL={BLUR_DATA_URL_BASE64}
                        height={10}
                        width={16}
                        layout="responsive"
                        sizes="(max-width: 767px) 120px, (max-width: 991px) 180px, (max-width: 1190px) 200px, 240px"
                      />
                    )
                  }
                </a>
              </CustomLink>
            </Col>
            <Col sm={8} xs={7} className="d-flex flex-column justify-content-between">
              <div className={styles.postDesc}>
                {
                  data?.oCategory?.sName && !hideBadge && (
                    <Badge
                      bg={data?.ePlatformType === 'de' ? 'danger' : 'primary'}
                      className={`${styles.badge} ${articleStyles.badge} ${data?.isVideo && 'video-badge'}  mb-1`}
                    >
                      <CustomLink href={`/${data?.oCategory?.oSeo?.sSlug}` || ''} prefetch={false}>
                        <a>{data?.oCategory?.sName}</a>
                      </CustomLink>
                    </Badge>
                  )
                }
                {
                  data && isLarge && (
                    <>
                      <h3 className={`${styles.title} small-head mb-2`}>
                        <CustomLink href={`/${data?.oSeo?.sSlug}` || ''} prefetch={false}>
                          <a>{data?.sTitle}</a>
                        </CustomLink>
                      </h3>
                      <p className="d-none d-sm-block">{data?.sSrtTitle || data?.sTitle}</p>
                    </>
                  )
                }
                {
                  data && !isLarge && (
                    <>
                      <h4 className={`${styles.title} small-head mb-2`}>
                        <CustomLink href={`/${data?.oSeo?.sSlug}` || ''} prefetch={false}>
                          <a>{data?.sSrtTitle || data?.sTitle}</a>
                        </CustomLink>
                      </h4>
                    </>
                  )
                }
              </div>
              {
                isVideo && data?.dPublishDate && (
                  <div className={`${articleStyles.articleInfo} ${styles.articleInfo} d-flex`}>
                    <MyImage src={time} alt='time' />
                    <span className="d-flex align-items-center">
                      {convertHMS(data?.nDurationSeconds)}
                    </span>
                  </div>
                )
              }
              {
                (data?.dPublishDisplayDate || data?.dPublishDate || data?.nDuration?.toString()) && !isVideo &&
                (
                  <div className={`${articleStyles.articleInfo} d-flex`}>
                    {(data?.dPublishDisplayDate || data?.dPublishDate) && (
                      <span className="d-flex align-items-center">
                         <MyImage
                       src={Calendar}
                       alt="Calendar Icon"
                       height="15"
                       width="15" />
                       <span className='ms-1'>
                        {convertDt24h(dateCheck(data?.dPublishDisplayDate || data?.dPublishDate))}
                       </span>
                      </span>
                    )}
                    <span className="d-flex align-items-center">
                    <MyImage
                       src={View}
                       alt="View Icon"
                       height="15"
                       width="15" />
                      {/* {data?.nDuration > 0 ? data?.nDuration : DEFAULT_BLOG_READ} <Trans i18nKey="common:Minute" /> */}
                      <span className='ms-1'>
                        {data?.nViewCount > -1 ? data?.nViewCount : DEFAULT_BLOG_READ}
                      </span>
                    </span>
                  </div>
                )
              }
            </Col>
          </Row>
        </article>
      )}
    </ArticleStyles>
  )
}
ArticleSmall.propTypes = {
  isLarge: PropTypes.bool,
  data: PropTypes.object,
  isVideo: PropTypes.bool,
  isFirstImg: PropTypes.bool,
  isSmall: PropTypes.bool,
  hideBadge: PropTypes.bool,
  index: PropTypes.number
}

export default ArticleSmall
